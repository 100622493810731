@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* ERROR 404
========================================================================== */
.molecule__error-404-container {
  display: flex;
  flex-direction: column;

  /* MEDIA
  ========================================================================== */
  .media-container {
    margin-bottom: $s-xl;

    @include gt-phone {
      margin-bottom: $s-xxl;
    }
  }

  /* CONTENT
  ========================================================================== */
  .content-container,
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-container {
    margin-bottom: $s-s;

    @include gt-phone {
      flex-direction: row;
      gap: $s-zs;
    }
  }

  .description {
    margin-bottom: $s-md;

    text-align: center;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    font-weight: 400;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    gap: $s-lg;
  }
}
